import { MobileMenuLink } from '../app/interfaces/mobile-menu-link';

export const mobileMenuLinks: MobileMenuLink[] = [
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'Products',
        url: '/shop/category/products'
    },
    {
        title: 'Accessories',
        url: '/shop/category/accessories',
    },
    {
        title: 'Spare Parts',
        url: '/shop/category/spares',
    },
    {
        title: 'Find Showroom',
        url: '/site/showroom',
    },
    {
        title: 'Find Service Center',
        url: '/site/service-center',
    },
];

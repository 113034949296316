import { MainMenuLink } from '../app/interfaces/main-menu-link';

export const mainMenu: MainMenuLink[] = [
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'Products',
        url: '/shop/category/products'
    },
    {
        title: 'Accessories',
        url: '/shop/category/accessories',
    },
    {
        title: 'Spare Parts',
        url: '/shop/category/spares',
    },
];

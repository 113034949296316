import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    NgZone,
    OnDestroy, OnInit,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { MobileMenuService } from '../../../../services/mobile-menu.service';
import { VehiclePickerModalService } from '../../../../services/vehicle-picker-modal.service';
import { Observable, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { fromOutsideClick } from '../../../../functions/rxjs/from-outside-click';
import { delay, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { CartService } from '../../../../services/cart.service';
import { ShopApi, VehicleApi } from '../../../../api/base';
import { TranslateService } from '@ngx-translate/core';
import { WishlistService } from '../../../../services/wishlist.service';
import { Product } from 'projects/storefront/src/app/interfaces/product';
import { UrlService } from 'projects/storefront/src/app/services/url.service';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroy$: Subject<void> = new Subject<void>();

    vehiclePickerIsOpen = false;

    searchIsOpen = false;

    query$: Subject<string> = new Subject<string>();

    suggestionsIsOpen = false;

    hasSuggestions = false;

    products: Product[] = [];

    searchPlaceholder$: Observable<string>;

    @HostBinding('class.mobile-header') classMobileHeader = true;

    @ViewChild('searchForm') searchForm: ElementRef<HTMLElement>;

    @ViewChild('searchInput') searchInput: ElementRef<HTMLElement>;

    @ViewChild('searchIndicator') searchIndicator: ElementRef<HTMLElement>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private zone: NgZone,
        private vehiclesApi: VehicleApi,
        private translate: TranslateService,
        public menu: MobileMenuService,
        public vehiclePicker: VehiclePickerModalService,
        public cart: CartService,
        public wishlist: WishlistService,
        private shopApi: ShopApi,
        public url: UrlService,
    ) { }

    ngOnInit(): void {
        this.searchPlaceholder$ = this.translate.stream('INPUT_SEARCH_PLACEHOLDER');
        this.query$.pipe(
            distinctUntilChanged(),
            switchMap(query => this.shopApi.getSearchSuggestions(query, {
                limitProducts: 3,
                limitCategories: 4,
            })),
            takeUntil(this.destroy$),
        ).subscribe(
            result => {
                if (result.products.length === 0 && result.categories.length === 0) {
                    this.hasSuggestions = false;
                    return;
                }

                this.hasSuggestions = true;
                this.products = result.products;
            }
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            fromOutsideClick([
                this.searchForm.nativeElement,
                this.searchIndicator.nativeElement,
            ]).pipe(
                filter(() => this.searchIsOpen && !this.vehiclePickerIsOpen),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                this.zone.run(() => this.closeSearch());
            });
        });
    }

    openSearch(): void {
        this.searchIsOpen = true;

        if (this.searchInput.nativeElement) {
            this.searchInput.nativeElement.focus();
        }
    }

    closeSearch(): void {
        this.searchIsOpen = false;
        this.suggestionsIsOpen = false;
    }

    openVehiclePicker(): void {
        this.vehiclesApi.currentVehicle$.pipe(
            switchMap(vehicle => {
                this.vehiclePickerIsOpen = true;

                const session = this.vehiclePicker.show(vehicle);

                session.close$.pipe(
                    delay(10),
                    takeUntil(this.destroy$),
                ).subscribe(() => {
                    this.vehiclePickerIsOpen = false;
                    this.searchInput.nativeElement.focus();
                });

                return session.select$.asObservable();
            }),
            takeUntil(this.destroy$),
        ).subscribe(vehicle => {
            this.vehiclesApi.setCurrentVehicle(vehicle);
        });
    }

    search(query: string): void {
        this.query$.next(query);
    }

    toggleSuggestions(force?: boolean): void {
        this.suggestionsIsOpen = force !== undefined ? force : !this.suggestionsIsOpen;
    }

    onInput(event: Event): void {
        const input = event.target as HTMLInputElement;

        this.search(input.value);
    }

    onInputFocus(event: FocusEvent): void {
        const input = event.target as HTMLInputElement;

        this.toggleSuggestions(true);
        this.search(input.value);
    }
}

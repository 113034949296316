import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product, ProductEmiPrices } from '../interfaces/product';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { map } from 'rxjs/operators';

export interface BuyNowItem {
  product: Product;
  options?: {
    name: string;
    value: string;
  }[];
  quantity: number;
  paymentType?: string;
}

interface BuyNowTotal {
  title: string;
  price: number;
  type: 'shipping' | 'fee' | 'tax' | 'other';
}

interface BuyNowData {
  items: BuyNowItem[];
  quantity: number;
  subtotal: number;
  totals: BuyNowTotal[];
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export class BuyNowService {
  private data: BuyNowData = {
    items: [],
    quantity: 0,
    subtotal: 0,
    totals: [],
    total: 0,
  };

  private emiInfo: ProductEmiPrices;

  private itemsSubject$: BehaviorSubject<BuyNowItem[]> = new BehaviorSubject(this.data.items);
  private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
  private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
  private totalsSubject$: BehaviorSubject<BuyNowTotal[]> = new BehaviorSubject(this.data.totals);
  private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
  private onAddingSubject$: Subject<Product> = new Subject();
  private emiInfoSubject$: BehaviorSubject<ProductEmiPrices> = new BehaviorSubject(null);

  get items(): ReadonlyArray<BuyNowItem> {
    return this.data.items;
  }

  get subtotal(): number {
    return this.data.subtotal;
  }

  get totals(): ReadonlyArray<BuyNowTotal> {
    return this.data.totals;
  }

  get quantity(): number {
    return this.data.quantity;
  }

  get total(): number {
    return this.data.total;
  }

  get emiPrices(): ProductEmiPrices {
    return this.emiInfo;
  }

  readonly items$: Observable<BuyNowItem[]> = this.itemsSubject$.asObservable();
  readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
  readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
  readonly totals$: Observable<BuyNowTotal[]> = this.totalsSubject$.asObservable();
  readonly total$: Observable<number> = this.totalSubject$.asObservable();
  readonly emiInfo$: Observable<ProductEmiPrices> = this.emiInfoSubject$.asObservable();

  readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.load();
      this.calc();
    }
  }

  add(product: Product, quantity: number, paymentType: string): Observable<BuyNowItem> {
    // timer only for demo
    return timer(10).pipe(map(() => {
      this.onAddingSubject$.next(product);

      let item = this.items.find(eachItem => {
        if (eachItem.product.id !== product.id) {
          return false;
        }

        return true;
      });

      if (item) {
        item.quantity += quantity;
      } else {
        item = { product, quantity, paymentType };

        this.data.items.push(item);
      }

      this.save();
      this.calc();

      return item;
    }));
  }

  update(updates: { item: BuyNowItem, quantity: number }[]): Observable<void> {
    // timer only for demo
    return timer(10).pipe(map(() => {
      updates.forEach(update => {
        const item = this.items.find(eachItem => eachItem === update.item);

        if (item) {
          item.quantity = update.quantity;
        }
      });

      this.save();
      this.calc();
    }));
  }

  remove(item: BuyNowItem): Observable<void> {
    // timer only for demo
    return timer(10).pipe(map(() => {
      this.data.items = this.data.items.filter(eachItem => eachItem !== item);

      this.save();
      this.calc();
    }));
  }

  updateEmiInfo(item: ProductEmiPrices): Observable<ProductEmiPrices> {
    return timer().pipe(map(() => {
      this.emiInfo = item;

      return item;
    }));
  }

  clear(): Observable<void> {
    return timer(0).pipe(
      map(() => {
        this.data.items = [];
        this.emiInfo = null;

        this.save();
        this.calc();
      })
    )
  }

  private calc(): void {
    let quantity = 0;
    let subtotal = 0;

    this.data.items.forEach(item => {
      quantity += item.quantity;
      subtotal += item.product.price * item.quantity;
    });

    const totals: BuyNowTotal[] = [];

    const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

    this.data.quantity = quantity;
    this.data.subtotal = subtotal;
    this.data.totals = totals;
    this.data.total = total;

    this.itemsSubject$.next(this.data.items);
    this.quantitySubject$.next(this.data.quantity);
    this.subtotalSubject$.next(this.data.subtotal);
    this.totalsSubject$.next(this.data.totals);
    this.totalSubject$.next(this.data.total);
    this.emiInfoSubject$.next(this.emiInfo);
  }

  private save(): void {
    localStorage.setItem('buyNowItems', JSON.stringify(this.data.items));
    localStorage.setItem('emiInfo', JSON.stringify(this.emiInfo));
  }

  private load(): void {
    const items = localStorage.getItem('buyNowItems');

    if (items) {
      this.data.items = JSON.parse(items);
    }
    const emiInfo = localStorage.getItem('emiInfo');
    if (emiInfo) {
      this.emiInfo = JSON.parse(emiInfo);
    }
  }
}

<h5 class="footer-links__title">{{ header }}</h5>
<ul class="footer-links__list">
    <li class="footer-links__item" *ngFor="let link of links">
        <ng-container *ngIf="link.external">
            <a [href]="link.url" target="_blank" rel="noopener">{{ link.title }}</a>
        </ng-container>
        <ng-container *ngIf="!link.external">
            <a [routerLink]="link.url" class="footer-links__link">{{ link.title }}</a>
        </ng-container>
    </li>
</ul>
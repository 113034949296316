import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AddressApi } from '../base/address.api';
import { environment } from '../../../environments/environment';
import { Division, District, Area } from '../../interfaces/address';
import { LanguageService } from '../../modules/language/services/language.service';

@Injectable()
export class RestAddressApi extends AddressApi {
  private url = `${environment.apiUrl}/${this.language.current.code}/shipping`;

  constructor(
    private http: HttpClient,
    private language: LanguageService,
  ) {
    super();
  }

  getDivisions(): Observable<Division[]> {
    return this.http.get<Division[]>(`${this.url}/division-all`);
  }

  getDistricts(division: number): Observable<District[]> {
    return this.http.get<District[]>(`${this.url}/district-by-division/${division}`);
  }

  getAreas(district: number): Observable<Area[]> {
    return this.http.get<Area[]>(`${this.url}/area-by-district/${district}`);
  }
}
<a routerLink="/" class="logo">
    <div class="logo__slogan">
        {{ 'TEXT_SLOGAN'|translate }}
    </div>
    <div class="logo__image">
        <!-- logo -->
        <img src="assets/images/logo.png" alt="Runner" width="168">
        <!-- logo / end -->
    </div>
</a>

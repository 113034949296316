<div class="product-form__body" [formGroup]="form">
    <div class="product-form__row">
        <div class="product-form__title">Color</div>
        <div class="product-form__control">
            <div class="input-radio-color__list">
                <label *ngFor="let value of colors" class="input-radio-color__item"
                    [class.input-radio-color__item--white]="isWhite(value.color_code)" [style.color]="value.color_code"
                    [title]="value.color" [tooltip]="value.color" container="body">
                    <input type="radio" name="productId" [value]="value.id" formControlName="productId">
                    <span></span>
                </label>
            </div>
        </div>
    </div>
</div>
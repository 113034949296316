import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ProductColor } from '../../../../interfaces/product';
import {
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-product-form',
    templateUrl: './product-form.component.html',
    styleUrls: ['./product-form.component.scss'],
})
export class ProductFormComponent implements OnInit {
    form: FormGroup;

    @Input() colors: ProductColor[] = [];

    @Input() productId: number;

    @HostBinding('class.product-form') classProductForm = true;

    constructor(
        private fb: FormBuilder,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            productId: this.productId,
        });
        this.form.get('productId').valueChanges.subscribe(id => {
            const product = this.colors.find(_ => _.id === id);
            this.router.navigateByUrl('/shop/products/' + product.slug);
        })
    }

    isWhite(color: string): boolean {
        return color === '#ffffff';
    }
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let response: HttpResponse<any>;
    let error: HttpErrorResponse;

    // extend server response observable with logging
    return next.handle(req)
      .pipe(
        tap(
          // Succeeds when there is a response; ignore other events
          res => {
            if (res instanceof HttpResponse) {
              response = res;
            }
          },
          // Operation failed; error is an HttpErrorResponse
          err => {
            error = err;
          }
        ),
        // Log when response observable either completes or errors
        finalize(() => {
          const elapsed = Date.now() - started;
          const message = `${response && response.status || error && error.status} ${response && response.statusText || error && error.status} after ${elapsed} ms: ${req.method} ${req.url}`;

          // log only in development mode
          if (environment.development) {
            if (console.groupCollapsed) {
              console.groupCollapsed(message);
            } else {
              console.log(message);
            }
            // print request params
            if (req.params.keys().length) {
              console.log('--- request params -----------------------');
              console.log(req.params.toString());
            }
            // print request body
            if (req.body) {
              console.log('--- request body -----------------------');
              console.log(JSON.stringify(req.body, null, 3));
            }
            // print server response
            console.log('--- server response --------------------');
            console.log(JSON.stringify(response && response.body || error && error.error, null, 3));

            if (console.groupEnd) {
              console.groupEnd();
            }
          }
        })
      );
  }
}

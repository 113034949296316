import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Country } from '../../../../interfaces/country';
import { AddressApi } from '../../../../api/base';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Area, District, Division } from 'projects/storefront/src/app/interfaces/address';
import { mobileValidator } from 'projects/storefront/src/app/functions/validators/mobile';

let uniqueId = 0;

export interface AddressFormValue {
    name: string;
    address_line_1: string;
    address_line_2: string;
    area: string;
    district: string;
    division: string;
    zip: string;
    email: string;
    mobile: string;
}

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true,
        },
    ],
})
export class AddressFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    private destroy$: Subject<void> = new Subject<void>();
    private readonly dataId: number = ++uniqueId;

    form: FormGroup;

    countries: Country[] = [];

    divisions: Division[] = [];

    districts: District[] = [];

    areas: Area[] = [];

    get formId(): string {
        return `app-address-form-id-${this.dataId}`;
    }

    changeFn: (_: AddressFormValue) => void = () => { };

    touchedFn: () => void = () => { };

    constructor(
        private fb: FormBuilder,
        private addressService: AddressApi,
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            address_line_1: ['', Validators.required],
            address_line_2: [''],
            zip: [''],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', Validators.required],
            area: '',
            district: '',
            division: '',
            area_id: ['', Validators.required],
            district_id: ['', Validators.required],
            division_id: ['', Validators.required],
        }, { validators: [mobileValidator('mobile')] });

        this.form.valueChanges.subscribe(value => {
            this.changeFn(value);
            this.touchedFn();
        });

        this.addressService.getDivisions().pipe(takeUntil(this.destroy$)).subscribe(x => this.divisions = x);
        // this.countriesService.getCountries().pipe(takeUntil(this.destroy$)).subscribe(x => this.countries = x);

        this.form.get('division_id').valueChanges.subscribe(val => {
            const division = this.divisions.find(({id}) => id === +val);
            this.form.get('division').setValue(division.name);

            this.addressService.getDistricts(val)
            .pipe(takeUntil(this.destroy$))
            .subscribe(x => this.districts = x);
        });
        this.form.get('district_id').valueChanges.subscribe(val => {
            const district = this.districts.find(({id}) => id === +val);
            this.form.get('district').setValue(district.name);

            this.addressService.getAreas(val)
            .pipe(takeUntil(this.destroy$))
            .subscribe(x => this.areas = x);
        });
        this.form.get('area_id').valueChanges.subscribe(val => {
            const area = this.areas.find(({id}) => id === +val);
            this.form.get('area').setValue(area.name);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable({ emitEvent: false });
        } else {
            this.form.enable({ emitEvent: false });
        }
    }

    writeValue(value: any): void {
        if (typeof value !== 'object') {
            value = {};
        }

        this.form.setValue(
            {
                name: '',
                address_line_1: '',
                address_line_2: '',
                area: '',
                district: '',
                division: '',
                zip: '',
                email: '',
                mobile: '',
                area_id: null,
                district_id: null,
                division_id: null,
                ...value,
            },
            { emitEvent: false },
        );
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.form.valid ? null : { addressForm: this.form.errors };
    }

    markAsTouched(): void {
        this.form.markAllAsTouched();
    }
}

<p>This document is published in respect of&nbsp; the terms &amp; conditions along with Privacy Policy which
    are legally binding on both of you and us under the existing laws of the country and lays out the terms and
    conditions and rules, as maybe amended and supplemented, from time to time (&ldquo;Terms&rdquo;) which shall be
    applicable to your present and future access and use of the Website of<a
        href="https://motorcycles.runnerautomobiles.com"> https://motorcycles.runnerautomobiles.com</a></p>
<p>This User Agreement (&ldquo;Terms and Conditions&rdquo; or "T&amp;C" or &ldquo;Terms&rdquo; or "Agreement")
    is between you (&ldquo;You&rdquo; or &ldquo;End User&rdquo; or &ldquo;Your&rdquo; or &ldquo;Buyer&rdquo; or
    &ldquo;Customer&rdquo; or &ldquo;User&rdquo;, &ldquo;Registered User&rdquo;) and Runner Automobiles Limited
    (&ldquo;Company&rdquo; or &ldquo;Us&rdquo; or &ldquo;We&rdquo; or &ldquo;Runner Automobiles&rdquo;). Seller shall
    mean authorized Parts distributors or Dealer of Runner Automobiles Limited and/or Authorised Dealer or any
    authorized point of Runner Automobiles Limited that can sell listed Products online.</p>
<p>Notwithstanding anything contained or said in any other document, if there is a conflict between the terms
    mentioned herein below and any other document, the terms contained in the present Terms of Use shall alone prevail
    for the purposes of usage of the Website.</p>
<p>1. INTRODUCTION</p>
<p>The domain name &ldquo;runnerautomobiles.com&rdquo; (hereinafter referred to as "Website") is owned by
    Runner Automobiles Limited.&nbsp;</p>
<p>2. ELIGIBILITY OF USER</p>
<p>Usage/browsing on the Website are completely free for the visitors. Runner Automobiles does not charge any
    fee for browsing the website.</p>
<p>Usage of this website is allowed, and available only to the persons who can form a legally binding contract
    under the Contract Act, 1872 and/or any such law. If you are a minor i.e. under the age of 18 (eighteen) years, you
    are not eligible to use the website and may only use the website with the involvement of a parent or guardian.</p>
<p>Runner Automobiles Limited reserves the right to terminate your login (if any) and/or refuse to provide you
    with access to the website if it is brought to Runner Automobiles&rsquo; notice or if it is discovered that you are
    under the age of 18 (eighteen) years and/or violates any provision of the Terms of Use.</p>
<p>3. CHANGE OF TERMS &amp; CONDITIONS</p>
<p>We reserve the right at our discretion to change, modify, add or remove any portions of these Terms of Use,
    Privacy policy, Return Policy, at any time without any prior written notice to you. For this reason, we encourage
    you to review these Terms of Use every time you purchase products from us or use our Website.</p>
<p>4. USER RESPONSIBILITY AND REGISTRATION OBLIGATIONS</p>
<ol>
    <li>
        <p role="presentation">We reserve the right to terminate your login Account, if any and/or refuse to
            provide you with access to the website if it is brought to our notice that user is not an eligible member or
            for any other reason at Runner Automobiles&rsquo; discretion. You agree that if you provide any information
            that is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that such
            information is untrue, inaccurate, not current incomplete, or not in accordance with these Terms of Use, we
            shall have the right to indefinitely suspend or terminate or block access of your user account on the
            website and refuse to provide you with access to the website.<br /><br /></p>
    </li>
    <li>
        <p role="presentation">In the event that Runner Automobiles terminates your use of the Website or any
            service therein, Runner Automobiles may delete any content or other materials relating to your use of the
            Website and will have no liability to you or any third party for doing so. In case you have made a login for
            the website, you are solely responsible for maintaining the confidentiality of your user ID/your
            account/display name and password and for restricting access to your computer or any other device used to
            access the website to prevent unauthorized access to your Account and should reset password / inform us
            immediately if you have any reason to believe that your password has become known to anyone else, or if the
            password is being, or is likely to be, used in an unauthorized manner. You agree to accept responsibility
            for all activities that occur under your Accountandpassword. <br /><br /></p>
    </li>
    <li>
        <p role="presentation">You agree, undertake and confirm that your use of the Website shall be strictly
            governed by the following binding principles:</p>
    </li>
</ol>
<ol>
    <li>
        <p role="presentation">You shall not host, display, upload, modify, publish, transmit, update or share
            any information which:</p>
    </li>
</ol>
<ol>
    <li>
        <p role="presentation">belongs to another person and to which you do not have any right to use;</p>
    </li>
    <li>
        <p role="presentation">is misleading in any way;</p>
    </li>
    <li>
        <p role="presentation">is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
            pedophilic or menacing, libelous, invasive of another's privacy, hateful, racial, ethnically objectionable,
            disparaging, relating or encouraging money laundering, gambling, or otherwise unlawful in any manner
            whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent
            representation of women".</p>
    </li>
    <li>
        <p role="presentation">harasses or advocates harassment of another person;</p>
    </li>
    <li>
        <p role="presentation">promotes illegal activities or conduct that is abusive, threatening, obscene,
            defamatory or libelous;</p>
    </li>
    <li>
        <p role="presentation">infringes upon or violates any third party's rights including, but not limited
            to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of
            a person's name, email address, physical address or phone number) or rights of publicity;</p>
    </li>
    <li>
        <p role="presentation">provides instructional information about illegal violating someone's privacy,
            or providing or creating computer viruses;</p>
    </li>
    <li>
        <p role="presentation">tries to gain unauthorized access or exceeds the scope of authorized access to
            the website or to profiles, blogs, account information, bulletins, or other areas of the website or solicits
            passwords or personal identifying information for commercial or unlawful purposes from other users;</p>
    </li>
    <li>
        <p role="presentation">harm minors in any way;</p>
    </li>
    <li>
        <p role="presentation">infringes any patent, trademark, copyright, trade secret or other proprietary
            rights of Runner Automobiles or any third party</p>
    </li>
    <li>
        <p role="presentation">deceives or misleads the addressee / users about the origin of such messages or
            communicates any information which is grossly offensive or menacing in nature;</p>
    </li>
    <li>
        <p role="presentation">impersonates another person;</p>
    </li>
    <li>
        <p role="presentation">contains software viruses or any other computer code, files or programs
            designed to interrupt, destroy or limit the functionality of any computer resource;&nbsp;</p>
    </li>
    <li>
        <p role="presentation">shall not, directly or indirectly, offer, attempt to offer, trade or attempt to
            trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of
            any applicable law, rule, regulation or guideline for the time being in force;</p>
    </li>
</ol>
<p>If you believe that any content on the Website is affected due to above points, you may immediately notify
    us in writing and Runner Automobiles will make all reasonable endeavours to remove such content complained about
    within a reasonable time.</p>
<p>2. Any non-compliance of use of website would be dealt as per the policy.</p>
<p>5. PRIVACY</p>
<ol>
    <li>
        <p role="presentation">We view protection of your privacy as a very important principle. We clearly
            understand that you and your personal information is one of our most important assets. Privacy policy
            applicable on this website is available at Privacy Policy. If you object to our current Privacy Policy, you
            are discouraged to use the website.</p>
    </li>
</ol>
<p>6. COMMUNICATIONS</p>
<ol>
    <li>
        <p role="presentation">When you visit the Website or send e-mails to us, you are communicating with us
            electronically. You will be required to provide a valid phone number and email while placing an order with
            us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any
            other mode of communication. For contractual purposes, you consent to receive communications (including
            transactional, promotional and/or commercial messages), from us with respect to your use of the website
            and/or your order placed on the website.</p>
    </li>
    <li>
        <p role="presentation">Placement of order by an User on the Website is an offer to buy the product(s)
            and it shall not be construed as Seller's acceptance of User's offer to buy the product(s) ordered. The
            Seller retains the right to cancel any such order placed by the Buyer, at its sole discretion and the Buyer
            shall be intimated of the same by way of an email/SMS. Any transaction price paid by Buyer in case of such
            cancellation by Seller shall be refunded to the Buyer in due course. Further, the Seller may cancel an order
            wherein the quantities exceed the typical individual consumption. This applies both to the number of
            products ordered within a single order and the placing of several orders for the same product where the
            individual orders comprise a quantity that exceeds the typical individual consumption. What comprises a
            typical individual's consumption quantity limit shall be based on various factors and at the sole discretion
            of the Seller and may vary from individual to individual.</p>
    </li>
</ol>
<p>&nbsp;</p>
<p>7. INTELLECTUAL PROPERTY RIGHTS/ TRADEMARK, COPYRIGHT AND RESTRICTION</p>
<p>All content included on the website, such as text, graphics, logos, button icons, images, audio clips,
    digital downloads, data compilations, and software etc. are the property of Runner Automobiles and/or, its
    affiliates or its content suppliers and is protected under trademark and copyright laws and other relevant law(s) as
    applicable from time to time.</p>
<p>You shall not extract or re-utilise parts of the contents of the website without consent of the Runner
    Automobiles and / or its affiliates (as may be applicable). In particular, you shall not utilise any data mining,
    robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of
    any parts of this website, you shall also not create and/or publish your own database that features and/or
    substantial (e.g. prices and product listings etc.) parts of this website</p>
<p>8. ORDERING AND DELIVERY</p>
<ol>
    <li>
        <p role="presentation">FOR TWO WHEELERS/SCOOTERS/SPARES/ACCESSORIES:</p>
    </li>
</ol>
<ol>
    <li>
        <p role="presentation">During the course of booking the vehicle, buyer can choose the option of
            &lsquo;Full Payment' or &lsquo;EMI Payment&rsquo;. Based on the product category such as TWO
            WHEELERS/SCOOTERS/SPARES/ACCESSORIES this feature might vary.</p>
    </li>
    <li>
        <p role="presentation">The vehicle can be booked by paying the complete price of the vehicle along
            with registration and insurance fee; the minimum booking amount specified on the website.</p>
    </li>
    <li>
        <p role="presentation">The Buyer agrees to pay the additional/other charges to the seller directly as
            applicable on particular model/variant of the vehicle selected.</p>
    </li>
    <li>
        <p role="presentation">A vehicle may not be available for home delivery until full verification and
            relevant paperwork are done.</p>
    </li>
    <li>
        <p role="presentation">Runner Automobiles reserve the rights to imply additional charges for any extra
            services provided to the buyer.</p>
    </li>
    <li>
        <p role="presentation">Once the order is placed, the delivery address and primary information cannot
            be changed.</p>
    </li>
</ol>
<p>2. Mandatory things you must submit at showroom before the delivery of the bike (if purchased in Runner EMI
    facility, for cash purchase not all documents are required to submit)</p>
<ol>
    <li>
        <p role="presentation">Have to fill up the loan form and give a loan processing fees of BDT. 700.00
            (Taka Seven Hundred) only.. The amount may change anytime at the discretion of Runner.</p>
    </li>
    <li>
        <p role="presentation">Customer&rsquo;s NID copy</p>
    </li>
    <li>
        <p role="presentation">Customer&rsquo;s 4 (four) copy passport size picture</p>
    </li>
    <li>
        <p role="presentation">NID photocopy and one passport size picture of the 1st guarantor</p>
    </li>
    <li>
        <p role="presentation">NID photocopy and one passport size picture of the 2nd guarantor</p>
    </li>
    <li>
        <p role="presentation">1 (one) page of customer&rsquo;s MICR Cheque</p>
    </li>
    <li>
        <p role="presentation">Have to provide Non-Judicial Stamp or customers can pay BDT. 300.00 (Taka Three
            Hundred) only at the showroom so the company can buy it for the customer</p>
    </li>
    <li>
        <p role="presentation">Have to Provide a valid BKash or Rocket account number of the customer</p>
    </li>
</ol>
<p>3. Runner Automobiles does not take any responsibility if the buyer fails to receive a product on time and
    it may result in order cancelation.</p>
<p>&nbsp;</p>
<p>9. PRICES AND BILLING</p>
<ol>
    <li>
        <p role="presentation">The price of the products as mentioned on <a
                href="https://motorcycles.runnerautomobiles.com/">motorcycles.runnerautomobiles.com/</a>&nbsp; is the
            Maximum Retail Price (MRP) for the mentioned product. Such MRP shall be inclusive of all taxes as are
            applicable in Bangladesh. Additional charges may be charged depending upon the delivery process or other
            additional charges at the time of purchase before payment is made. Runner Automobiles reserve the right to
            collect taxes and/or such other duty and/or surcharge that it may have to incur in addition to the normal
            taxes it may have to pay. Other charges may include but not limited to insurance, registration fees, and
            other charges etc. these charges can be paid directly at any authorised show room of Runner Automobiles or
            by online payment by the buyer. The buyer can also book and make payment of a bike through the website. The
            bike can be collected from&nbsp; nearby showroom.<br /><br /></p>
    </li>
    <li>
        <p role="presentation">ORDER CANCELLATION: Due to unavoidable circumstances, there may be times when
            certain orders having been validly placed may not be processed or capable of being dispatched. Runner
            Automobiles Limited reserves the exclusive right to refuse or cancel any order for any reason. The
            confirmation may be dependable on additional verifications or information before accepting any order. We
            will contact you if all or any portion of your order is cancelled or if additional information is required
            to accept your order. If your order is cancelled after your credit card/ debit card/ any other mode of
            payment has been charged, the said amount will be&nbsp; refunded accordingly. The period for refund may
            vary.</p>
    </li>
</ol>
<p>10. PAYMENT</p>
<ol>
    <li>
        <p role="presentation">All payments made against the purchases on <a
                href="https://motorcycles.runnerautomobiles.com/">motorcycles.runnerautomobiles.com/</a> by you shall be
            as against the MRP/booking amount displayed on the website and shall be in terms and conditions of the third
            party Online Payment Gateway Services as adopted and applicable to your transaction as approved by Runner
            Automobiles Ltd. Due to any technical issues if the MRP or any price of the website is not showring
            correctly Runner holds the right to change or modify the price without any prior notice. Runner will not be
            responsible to sell the product if any price is showing incorrectly due to technical problems.&nbsp;</p>
    </li>
    <li>
        <p role="presentation">Before we proceed your order and deliver your product(s) to you, you along with
            other documents have to provide supporting documents (including but not limited to Govt. issued NID, address
            proof, nominee information) to establish the ownership of the payment. This is done so as to ensure a safe
            and a fool-proof online shopping environment to all of our users.</p>
    </li>
    <li>
        <p role="presentation">Runner Automobiles may employ other third party facilities, for payments on the
            website. Such payments can be made through the electronic or through cash on delivery transactions, as may
            be permitted by Runner Automobiles Ltd. which shall be at its sole discretion. Use of such third party
            services will be governed by their User Agreement, Conditions and other rules and policies as may be
            required and applicable for your nature of activities.</p>
    </li>
    <li>
        <p role="presentation">You as a buyer accept that upon initiating a transaction, you are entering into
            a legally binding and enforceable contract with the Seller to purchase the products and /or services from
            the Seller using the payment facility, and buyer shall pay the transaction price through their issuing bank
            to the seller using the payment facility.</p>
    </li>
    <li>
        <p role="presentation">You, as a Buyer, understand that the Payment Facility may not be available in
            full or in part for certain category of products and/or services and/or Transactions as mentioned in the
            Policies and hence, you may not be entitled to a refund in respect of the Transactions for those products
            and/or services.</p>
    </li>
    <li>
        <p role="presentation">Runner Automobiles reserves the right to impose limits on the number of
            transactions or transaction price which&nbsp; it may receive from&nbsp; a particular Valid
            Credit/Debit/Card/Valid Bank Account or such other infrastructure or any other financial instrument directly
            or indirectly through payment aggregator or through any such facility authorized by Bangladesh Bank.</p>
    </li>
    <li>
        <p role="presentation">It is hereby clarified that the &lsquo;Cash on Delivery&rsquo; feature for
            payment may be disabled for certain account users, at the sole discretion of Runner Automobiles.</p>
    </li>
    <li>
        <p role="presentation">The buyer acknowledges that Runner Automobiles will not be liable for any
            damages, interests or claims etc. resulting from not processing a Transaction/Transaction Price or any delay
            in processing a Transaction/Transaction Price which is beyond control of Runner Automobiles.</p>
    </li>
</ol>
<p>11.&nbsp; REFUNDS &amp; RETURNS</p>
<ol>
    <li>
        <p role="presentation">Runner Automobiles holds the right to determine whether a refund can be issued.
            For further information, please read our Return Policy.</p>
    </li>
    <li>
        <p role="presentation">Runner Automobiles shall make all endeavors to deliver defect free products to
            the buyers.</p>
    </li>
</ol>
<p>12. DISCLAIMERS:</p>
<ol>
    <li>
        <p role="presentation">We try to ensure that the products description displayed on&nbsp; the website
            are as accurate as possible. However, Runner Automobiles does not warrant that the products&rsquo;
            descriptions or other contents are accurate, complete, reliable, current, or error-free. If a product
            offered by Runner Automobiles is not as described, you may contact to Runner Automobiles within 10 (ten)
            days of receipt and Runner Automobiles reserves the right to provide a solution as per its discretion.
            Runner Automobiles is not liable to issue any refunds or allow exchanges and/or returns as a matter of
            policy.</p>
    </li>
    <li>
        <p role="presentation">The prices displayed on our website may differ from prices that are available
            in stores/ dealer points. Further, the prices displayed are quotes which may vary from time to time for the
            same product. Prices shown on the website are subject to change without prior notice. These prices only
            reflect the MRP and do not include delivery/ registration/insurance fee which may be extra as applicable.
        </p>
    </li>
    <li>
        <p role="presentation">There may be information on our website that may contain errors, inaccuracies,
            or omissions that may relate to product descriptions, pricing, and availability. Runner Automobiles reserves
            the right to correct any errors, inaccuracies or omissions and to change or update information at any time
            without prior notice and in such an event Seller/Runner Automobiles may cancel such your order(s).</p>
    </li>
</ol>
<p>13. COMPLIANCE</p>
<p>All the products displayed and/sold on the Runner Automobiles website are manufactured/ procured as per the
    Bangladesh law and comply with the industry standard.</p>
<p>14. WARRANTIES &amp; INDEMNITY</p>
<ol>
    <li>
        <p role="presentation">All the products follow the warranty policy issued by the company.<br /><br />
        </p>
    </li>
    <li>
        <p role="presentation">Runner Automobiles Ltd.,&nbsp; its management, affiliates, subsidiaries, group
            companies (as applicable) and their respective officers, directors, agents, and employees, will be held
            harmless from any claim or demand, or actions including reasonable lawyers' fees, made by any third party or
            penalty imposed due to or arising out of your breach of this Terms of Use, privacy policy and other
            policies, or your violation of any law, rules or regulations or the rights (including infringement of
            intellectual property rights) of a third party.<br /><br /></p>
    </li>
</ol>
<p>15. BREACH</p>
<ol>
    <li>
        <p role="presentation">In the event you are found to be in breach of the Terms of Use or Privacy
            Policy or other rules and policies or if we are unable to verify or authenticate any information you provide
            or if it is believed that your actions may cause legal liability for you, other users or us, without
            limiting to the present, without prior notice immediately limit your activity, remove your information,
            temporarily/indefinitely suspend or terminate or block your membership, and/or refuse to provide you with
            access to this Website. Any user that has been suspended or blocked may not register or attempt to register
            with us or use the Website in any manner whatsoever until such time that such user is reinstated by
            us.<br /><br /></p>
    </li>
    <li>
        <p role="presentation">Notwithstanding the foregoing, if you breach the Terms of Use or Privacy Policy
            or other rules and policies, we reserve the right to recover any amounts due and owing by you to us and to
            take strict legal action including but not limited to a referral to the police or other authorities for
            initiating criminal or other proceedings against you.</p>
    </li>
</ol>
<p>&nbsp;</p>
<ol start="3">
    <li>
        <p role="presentation">&nbsp;Any breach of any applicable laws shall also result in, without prior
            notice immediately limit your activity, remove your information, temporarily/indefinitely suspend or
            terminate or block your membership.</p>
    </li>
</ol>
<p><br />16. COMPLIANCE WITH LAWS:</p>
<p>&nbsp;</p>
<p>Buyer and Seller shall comply with all the applicable laws, rules, and notifications as issued from time to
    time.</p>
<p>17. SEVERABILITY</p>
<p>We reserve the right to make changes to our site, policies, and these Terms of Use at any time. If any of
    these Terms shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable
    and shall not affect the validity and enforceability of remaining Terms.&nbsp;</p>
<p>18. WAIVER</p>
<p>The failure by Runner Automobiles to enforce any term(s) or conditions of the Agreement shall not be a
    waiver by Runner Automobiles of them or of the right any time subsequent to enforce all Terms and Conditions of this
    agreement.<br /><br /></p>
<p>19. DISPUTE RESOLUTION</p>
<p>This document along with Privacy Policy and Return Policy shall be construed as legal relations between you
    and Runner Automobiles and shall be determined and governed according to the laws of Bangladesh and all disputes
    relating to this agreement shall be subject to the exclusive jurisdiction.</p>
<p>&nbsp;</p>
<p>20. GRIEVANCE</p>
<p>Any grievances you may have by way of use of the website can be addressed to the relevant Grievance
    officer. The &lsquo;Grievance officer&rsquo; shall thereafter consider the same and provide a response within
    reasonable time.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
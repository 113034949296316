export const theme = {
    name: 'Vespa E-Commerce',
    url: 'https://vespa.runnerautomobiles.com',
    author: {
        name: 'Battery Low Interactive',
        profile_url: 'https://batterylowinteractive.com',
    },
    contacts: {
        address: ['138/1 Tejgaon I/A, Dhaka-1208'],
        email: ['piaggio@runnerbd.com'],
        phone: ['09611222000, 16273, 01730407473, 01730407486'],
        hours: [
            'Head office: 09.00 am to 06.00 pm',
            'Service center:  09.00 am to 06.00 pm',
            'Showroom:  10.00 am to 08.00 pm'
        ],
    },
};

import { Observable } from 'rxjs';
import { HomeSlider } from '../../interfaces/home-slider';
import { SpecialOffer } from '../../interfaces/special-offer';
import { Showroom } from '../../interfaces/showroom';

export abstract class HomeApi {
    abstract getSliders(): Observable<HomeSlider[]>;

    abstract getSpecialOffer(): Observable<SpecialOffer>;

    abstract getShowrooms(): Observable<Showroom[]>;

    abstract getServiceCenters(): Observable<Showroom[]>;
}
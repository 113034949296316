import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function mobileValidator(mobile: string): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
        const field = control.get(mobile);
        const regex = /(^([+]{1}[8]{2}|0088)?(01)(3|7|8|6|9|5)\d{8})$/;
        const result = regex.exec(field.value);

        if (field && field.value) {
            if (!result) {
                const errors = field.errors || {};
                field.setErrors(Object.assign({}, errors, { mobile: true }));
            }
        }

        return null;
    };
}

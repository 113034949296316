import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ShopCategory } from '../../interfaces/category';
import { Product, ProductColor, ProductEmiInfo, ProductSlider } from '../../interfaces/product';
import { Brand } from '../../interfaces/brand';
import { ProductsList, ReviewsList } from '../../interfaces/list';
import { Review } from '../../interfaces/review';
import { Order, PaymentMethod } from '../../interfaces/order';
import {
    AddProductReviewData,
    CheckoutData,
    GetBrandsOptions,
    GetCategoriesOptions,
    GetCategoryBySlugOptions,
    GetProductReviewsOptions,
    GetProductsListOptions,
    GetSearchSuggestionsOptions,
    GetSearchSuggestionsResult,
    ShopApi,
} from '../base';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../modules/language/services/language.service';

@Injectable()
export class RestShopApi extends ShopApi {
    getBrands(options?: GetBrandsOptions): Observable<Brand[]> {
        throw new Error("Method not implemented.");
    }
    getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList> {
        throw new Error("Method not implemented.");
    }
    addProductReview(productId: number, data: AddProductReviewData): Observable<Review> {
        throw new Error("Method not implemented.");
    }
    getProductAnalogs(productId: number): Observable<Product[]> {
        throw new Error("Method not implemented.");
    }

    private url = `${environment.apiUrl}/${this.language.current.code}/product`;
    private orderUrl = `${environment.apiUrl}/${this.language.current.code}/order`;
    private paymentUrl = `${environment.apiUrl}/${this.language.current.code}/payment`;

    constructor(
        private http: HttpClient,
        private language: LanguageService,
    ) {
        super();
    }
    getCategoryBySlug(slug: string, options?: GetCategoryBySlugOptions): Observable<ShopCategory> {
        return this.http.get<ShopCategory>(`${this.url}/category/${slug}`);
    }

    getCategories(options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        return this.http.get<ShopCategory[]>(`${this.url}/category-all`);
    }

    // getBrands(options?: GetBrandsOptions): Observable<Brand[]> {
    //     return getBrands(options);
    // }

    getProductsList(options?: GetProductsListOptions): Observable<ProductsList> {
        return this.http.get<ProductsList>(`${this.url}/list`, {
            params: {
                type: options.type ? options.type : 'bike',
                page: options.page ? options.page.toString() : '1',
                limit: options.limit ? options.limit.toString() : '16',
                sort: options.sort ? options.sort : '',
                ...options.filters
            }
        });
    }

    getProductBySlug(slug: string): Observable<Product> {
        return this.http.get<Product>(`${this.url}/${slug}`);
    }

    getProductColors(itemModelId: number): Observable<ProductColor[]> {
        return this.http.get<ProductColor[]>(`${this.url}/color/${itemModelId}`);
    }

    getProductEmiInfo(productId: number): Observable<ProductEmiInfo> {
        return this.http.get<ProductEmiInfo>(`${this.url}/emi/${productId}`);
    }

    getProductSlider(productId: number): Observable<ProductSlider> {
        return this.http.get<ProductSlider>(`${this.url}/slider/${productId}`);
    }

    // getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList> {
    //     return getProductReviews(productId, options);
    // }

    // addProductReview(productId: number, data: AddProductReviewData): Observable<Review> {
    //     return addProductReview(productId, data);
    // }

    // getProductAnalogs(productId: number): Observable<Product[]> {
    //     return getProductAnalogs(productId);
    // }

    getRelatedProducts(productId: number, limit: number, cc?: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.url}/related/${productId}`, { 
            params: { 
                limit: limit.toString(), 
                cc: cc ? cc.toString() : ''
            } 
        });
    }

    getFeaturedProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.url}/featured/${categorySlug}`, { params: { limit: limit.toString() } });
    }

    getPopularProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.url}/popular/${categorySlug}`, { params: { limit: limit.toString() } });
    }    
    
    getMostViewedProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.url}/most-viewed/${categorySlug}`, { params: { limit: limit.toString() } });
    }

    getTopRatedProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.url}/top-rated/${categorySlug}`, { params: { limit: limit.toString() } });
    }

    getSpecialOffers(limit: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.url}/special-offer`, { params: { limit: limit.toString() } });
    }

    getLatestProducts(type: string, limit: number): Observable<Product[]> {
        return this.http.get<Product[]>(`${this.url}/latest/${type}`, { params: { limit: limit.toString() } });
    }

    getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult> {
        return this.http.get<GetSearchSuggestionsResult>(`${this.url}/search`, { params: { query } });
    }

    getPaymentMethods(): Observable<PaymentMethod[]> {
        return this.http.get<PaymentMethod[]>(`${this.paymentUrl}/payment-method`);
    }

    checkout(data: CheckoutData): Observable<Order> {
        return this.http.post<Order>(`${this.orderUrl}/create`, data);
    }
}
